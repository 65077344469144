import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Majani Soulful Vegan Cuisine",
  pageDescription:
    "Find out how a vegan fast-casual restaurant streamlined its in-house operations and doubled its online orders using SpotOn's restaurant POS system.",
  title: "Attracting more fast-casual vegan customers",
  subTitle: `How Majani Soulful Vegan Cuisine manages more guests with SpotOn.`,
  businessName: "Majani Soulful Vegan Cuisine",
  industry: "Restaurants & hospitality, casual dining",
  location: "Chicago, Illinois",
  products: [spotonProducts.RESTAURANT, spotonProducts.ORDER],

  description:
    "Chef T and Naysa Emmanuel bring plant-based southern food to the South Shore of Chicago with their fast-casual restaurant—Majani Soulful Vegan Cuisine. And by partnering with SpotOn, they've been able to streamline their operations and double their online orders.",
  quoteData: {
    imgName: "majani-soulful-vegan-cuisine.png",
    quote: `“With SpotOn Restaurant, we can take the order and send it to the kitchen one at a time so they can get on it right away. It feels like we’re making the same amount of money we were doing two or three years ago, but it’s so much easier because it’s spaced out so that you’re not crushed for time.”`,
    personName: "Chef T",
    personTitle: "Owner, Majani Soulful Vegan Cuisine",
  },
  goal: `The Emmanuels needed a restaurant POS system to simplify their ordering and payment processes. And because third-party platforms were charging fees on half of their online orders, they were looking for ways to reduce these charges.`,
  solution: `SpotOn Restaurant, integrated with a kitchen display system (KDS), sends orders directly to Majani's kitchen, eliminating errors and waste. And with an online menu through SpotOn Order, they can offer ordering from their website while avoiding third-party fees.`,
  solutionReadMore: {
    text: `Read more about their story.`,
    url: `https://spoton.com/blog/majani-cuisine-chicago-south-side/`,
  },
  results: {
    title: `The results`,
    stats: [
      {
        title: `100%`,
        description: `increase in direct online orders in one month`,
      },
      {
        title: `3-minute`,
        description: `decrease in order time`,
      },
      { title: `90%`, description: `decrease in order errors` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Selling tacos, turning tables",
    imageName: "la-chiva-loka.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-14",
    href: "/case-studies/la-chiva-loka",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Coordinating a Caribbean restaurant",
    imageName: "freetown-road-project.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-28",
    href: "/case-studies/freetown-road-project",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Selling more crepes with smarter data",
    imageName: "crepe-corner.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-21",
    href: "/case-studies/crepe-corner",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
];
